import React from 'react';
import {useNavigate} from 'react-router-dom';
import {CheckCircleOutlined} from '@ant-design/icons';
import {Button} from 'antd';

import {Banner} from '../../../../components/Banner/Banner';
import {INDEX} from '../../../../config/routes';

import cls from './Success.module.scss'

export const SuccessSMSRequest = () => {

    const navigate = useNavigate();

    return (
        <div className={cls.page}>
            <div className={'content'}>
                <div className={cls.text}>
                    <h2><CheckCircleOutlined className={'color-success'}/> Ваша заявка на возврат принята</h2>
                    <p>
                        Уважаемый Клиент,<br/>
                        Группа компаний &laquo;FTS Group&raquo; благодарит Вас за&nbsp;обращение.
                    </p>
                    <p>
                        Ваша заявка на&nbsp;возврат принята. Обработка заявки и&nbsp;возврат денежных средств
                        производится в&nbsp;течение 30&nbsp;рабочих дней с&nbsp;момента подачи заявки. Информация
                        по&nbsp;обработке заявки направляется строго на&nbsp;адрес электронной почты, который был указан
                        Вами в&nbsp;заявке.
                    </p>
                    <p>
                        Группа компаний &laquo;FTS Group&raquo; гарантирует возврат средств за&nbsp;сертификат,
                        в&nbsp;случае если это предусмотрено условиями сертификата.
                    </p>
                    <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)}>Хорошо</Button>
                </div>
            </div>

            <Banner/>

        </div>
    );
}


