import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import dayjs from 'dayjs';

import {
    ENUM_REFUNDREQUEST_TEMPLATE,
    ENUM_REFUNDREQUEST_TYPE,
    RefundRequestInput
} from '../../../__generated__/global-types';
import FormLayout from '../../../components/FormLayout/FormLayout';
import {
    ERROR_CERT_NUMBER,
    SUCCESS_DEFAULT_REQUEST, SUCCESS_DEFAULT_REQUEST_INS,
    SUCCESS_NON_RETURN,
    SUCCESS_ON_PERSONAL,
    SUCCESS_PURCHASE_PLACE,
    SUCCESS_SEVEN_DAYS, SUCCESS_SMS_REQUEST,
    SUCCESS_WALK_TO_MFO,
    SUCCESS_WALK_TO_OFFICE,
} from '../../../config/routes'
import {IFormRefundFree, ISignForm} from '../../../shared/forms/forms';
import {
    createRefundRequest,
    createRefundRequestVariables
} from '../../../shared/graphql/__generated__/createRefundRequest';
import {signRefundByCode, signRefundByCodeVariables} from '../../../shared/graphql/__generated__/signRefundByCode';
import {CREATE_REFUND_REQUEST,MUTATION_SIGN_REFUND_BY_CODE} from '../../../shared/graphql/refund';
import {Contacts} from '../../index/Contacts/Contacts';

import image from './doc.svg';
import RefundFreeForm from './RefundFreeForm';
import SignForm from './SignForm';


const RefundFree: FC = () => {

    const navigate = useNavigate();
    const [createRefundRequest, createRefundRequestData] = useMutation<createRefundRequest,createRefundRequestVariables>(CREATE_REFUND_REQUEST)
    const [signRefundByCode, signRefundByCodeData] = useMutation<signRefundByCode,signRefundByCodeVariables>(MUTATION_SIGN_REFUND_BY_CODE)

    const [sign, setSign] = useState(false);
    const [id, setId] = useState<string>('');

    const navigateByTemplate = (template:ENUM_REFUNDREQUEST_TEMPLATE, url?: string) => {
        switch (template) {
            case ENUM_REFUNDREQUEST_TEMPLATE.SEVEN_DAYS: return (navigate(SUCCESS_SEVEN_DAYS))
            case ENUM_REFUNDREQUEST_TEMPLATE.WALK_TO_MFO: return (navigate(SUCCESS_WALK_TO_MFO))
            case ENUM_REFUNDREQUEST_TEMPLATE.PURCHASE_PLACE: return (navigate(SUCCESS_PURCHASE_PLACE))
            case ENUM_REFUNDREQUEST_TEMPLATE.DEFAULT_REQUEST: return (navigate(SUCCESS_DEFAULT_REQUEST, {state: {url: url}}))
            case ENUM_REFUNDREQUEST_TEMPLATE.DEFAULT_REQUEST_INS: return (navigate(SUCCESS_DEFAULT_REQUEST_INS, {state: {url: url}}))
            case ENUM_REFUNDREQUEST_TEMPLATE.SMS_REQUEST: return (navigate(SUCCESS_SMS_REQUEST, {state: {url: url}}))
            case ENUM_REFUNDREQUEST_TEMPLATE.ON_PERSONAL: return (navigate(SUCCESS_ON_PERSONAL))
            case ENUM_REFUNDREQUEST_TEMPLATE.WALK_TO_OFFICE: return (navigate(SUCCESS_WALK_TO_OFFICE))
            case ENUM_REFUNDREQUEST_TEMPLATE.WALK_TO_MAIL: return (navigate(SUCCESS_WALK_TO_OFFICE, {state: {url: url}}))
            case ENUM_REFUNDREQUEST_TEMPLATE.NON_RETURN: return (navigate(SUCCESS_NON_RETURN))
            case ENUM_REFUNDREQUEST_TEMPLATE.CERT_NUMBER_ERROR: return (navigate(ERROR_CERT_NUMBER))
        }
    }

    const onSign = async (values: ISignForm) => {
        await signRefundByCode({
            variables: {id: id, code: values.code},
            onCompleted: (data) => {
                const template = data.signRefundByCode?.template!
                const url = data.signRefundByCode?.requestPdfFileUrl!
                navigateByTemplate(template, url)
            },
        })
    }

    const onComplete = async (values:IFormRefundFree) => {

        const data:RefundRequestInput = {
            FIO: values.FIO,
            Email: values.Email,
            Phone: values.Phone,
            requestData: {
                ...values,
                Certificate_Date: dayjs(values.Certificate_Date).format('YYYY-MM-DD'),
            },
            Certificate_Number: values.Certificate_Number,
            type: ENUM_REFUNDREQUEST_TYPE.free,
        }

        await createRefundRequest({
            variables: {
                data: data,
            },
            onCompleted: (data) => {
                const template = data.createRefundRequest?.data?.attributes?.template!
                const url = data.createRefundRequest?.data?.attributes?.requestPdfFileUrl!
                const signStatus = data.createRefundRequest?.data?.attributes?.signStatus!
                if (signStatus !== 'CREATED_1') {
                    navigateByTemplate(template, url)
                } else {
                    setSign(true)
                    setId(data.createRefundRequest?.data?.id!)
                }

            }
        })
    }

    return (
    <div>
        <div className={'content'}>
            {/*<Banners/>*/}
        </div>

        <FormLayout image={image}>
            <h2>Заявление на возврат</h2>
            <RefundFreeForm onComplete={onComplete} loading={createRefundRequestData.loading} disabled={createRefundRequestData.loading || sign}/>
            {sign && <SignForm onComplete={onSign} loading={signRefundByCodeData.loading} disabled={signRefundByCodeData.loading}/>}
        </FormLayout>

        <div className={'content'}>
            <Contacts/>
        </div>
        
    </div>
)};

export default RefundFree;



