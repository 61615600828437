import {useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {Button, Checkbox, DatePicker, Form, Input} from 'antd'
import {MaskedInput} from 'antd-mask-input';

import {ENUM_REFUNDREQUEST_TEMPLATE} from '../../../__generated__/global-types';
import {POLICY} from '../../../config/routes';
import {IFormRefundFree} from '../../../shared/forms/forms';
import {checkCertNumber, checkCertNumberVariables} from '../../../shared/graphql/__generated__/checkCertNumber';
import {QUERY_CHECK_CERT_NUMBER} from '../../../shared/graphql/refund';
import {accountRegex, bankRegex, certRegex, passportRegex, phoneRegex} from '../../../shared/helpers/regex';

interface RefundFreeFormProps {
    onComplete: (values: IFormRefundFree) => void
    loading: boolean
    disabled: boolean
}

const RefundFreeForm = ({onComplete, loading, disabled}: RefundFreeFormProps) => {

    const [form] = Form.useForm()
    const [agree, setAgree] = useState<boolean>(true)

    const [bank, setBank] = useState<boolean>(true)
    const [checkCertNumber] = useLazyQuery<checkCertNumber, checkCertNumberVariables>(QUERY_CHECK_CERT_NUMBER)

    const onFinish = (values: IFormRefundFree) => {
        onComplete({
            ...values,
            Bank_Name: bank ? values.Bank_Name : '',
            Bank_Number: bank ? values.Bank_Number : '',
            Bank_Account: bank ? values.Bank_Account : '',
            Bank_Correspondent: bank ? values.Bank_Correspondent : '',
        })
    }

    const certNumberValidate = async (rule: any, value: any) => {
        const queryResult = await checkCertNumber({
            variables: {Certificate_Number: value}
        });
        const valid = queryResult.data?.checkCertNumber.template !== ENUM_REFUNDREQUEST_TEMPLATE.CERT_NUMBER_ERROR
        setBank(queryResult.data?.checkCertNumber.details!)
        if (valid) {
            return Promise.resolve();
        } else {
            return Promise.reject('Ошибка в номере сертификата');
        }
    };

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onChange'} onFinish={onFinish}
              disabled={loading || disabled}>
            <Form.Item name={'Certificate_Number'} label={'Номер сертификата'}
                       hasFeedback
                       validateFirst={true}
                       validateDebounce={1000}
                       rules={[
                           {required: true, message: 'Заполните, пожалуйста'},
                           // {pattern: latinRegex, message: 'Только латинские буквы и цифры'},
                           {pattern: certRegex, message: 'Не соответствует формату номера сертификата'},
                           {validator: certNumberValidate}
                       ]}>
                <Input placeholder={'23000NSB176000000'}/>
            </Form.Item>
            <Form.Item name={'FIO'} label={'ФИО'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Иванов Иван Иванович'}/>
            </Form.Item>
            <Form.Item name={'Passport'} label={'Серия и номер паспорта'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: passportRegex, message: 'Не соответствует формату серии и номера паспорта'}
            ]}>
                <MaskedInput disabled={loading || disabled} mask={'0000 000000'} placeholder={'0000 000000'}
                             size={'large'}/>
            </Form.Item>
            <Form.Item name={'Phone'} label={'Номер телефона'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: phoneRegex, message: 'Не соответствует формату номера телефона'}
            ]}>
                <MaskedInput disabled={loading || disabled} mask={'+7 (000) 000-00-00'}
                             placeholder={'+7 (999) 888-77-66'} size={'large'}/>
            </Form.Item>
            <Form.Item name={'Email'} label={'Email'} rules={[{required: true, message: 'Заполните, пожалуйста'}, {
                type: 'email',
                message: 'Не соответствует формату email'
            }]}>
                <Input onChange={(e) => form.setFieldValue('Email', e.target.value.trim())}
                       placeholder={'name@example.com'}/>
            </Form.Item>
            <Form.Item name={'Certificate_Date'} label={'Дата выдачи сертификата'}
                       rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <DatePicker format={'DD.MM.YYYY'}/>
            </Form.Item>
            {bank &&
                <>
                    <Form.Item name={'Bank_Name'} label={'Наименование банка'}
                               rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                        <Input placeholder={'Банк России'}/>
                    </Form.Item>
                    <Form.Item name={'Bank_Correspondent'} label={'Корр. счет'} rules={[
                        {required: true, message: 'Заполните, пожалуйста'},
                        {pattern: accountRegex, message: 'Не соответствует формату номера счета'}
                    ]}>
                        <Input onChange={(e) => form.setFieldValue('Bank_Correspondent', e.target.value.trim())}
                               placeholder={'30101810900000000746'}/>
                    </Form.Item>
                    <Form.Item name={'Bank_Number'} label={'БИК'} rules={[
                        {required: true, message: 'Заполните, пожалуйста'},
                        {pattern: bankRegex, message: 'Не соответствует формату БИК'}
                    ]}>
                        <Input onChange={(e) => form.setFieldValue('Bank_Number', e.target.value.trim())}
                               placeholder={'044525600'}/>
                    </Form.Item>
                    <Form.Item name={'Bank_Account'} label={'Номер счета получателя'} rules={[
                        {required: true, message: 'Заполните, пожалуйста'},
                        {pattern: accountRegex, message: 'Не соответствует формату номера счета'}
                    ]}>
                        <Input onChange={(e) => form.setFieldValue('Bank_Account', e.target.value.trim())}
                               placeholder={'40817810712532748696'}/>
                    </Form.Item>
                </>
            }
            <Form.Item>
                <Checkbox checked={agree} onChange={() => setAgree(!agree)}>Согласен на обработку <a href={POLICY}
                                                                                                     target={'_blank'}
                                                                                                     rel={'noreferrer'}>персональных
                    данных и с политикой конфиденциальности</a></Checkbox>
            </Form.Item>
            {bank &&
                <Form.Item>
                    <p className={'caption'}>
                        Подписывая настоящее Заявление посредством внесения уникального кода из&nbsp;письма,
                        направленного на&nbsp;электронный адрес Клиента, указанный при заполнении формы на&nbsp;сайте
                        Компании, Клиент подтверждает, что ознакомлен и&nbsp;согласен с&nbsp;тем, что самостоятельно
                        несет ответственность за&nbsp;корректность предоставленных банковских реквизитов и&nbsp;данных
                        в&nbsp;заявление на&nbsp;возврат стоимости присоединения к&nbsp;Оферте &laquo;Финансовая
                        защита&raquo; и/или &laquo;Иммунитет+&raquo;. Данной подписью Клиент подтверждает, что
                        ознакомлен и&nbsp;согласен с&nbsp;тем, что в&nbsp;случае указания им&nbsp;в&nbsp;заявление
                        на&nbsp;возврат стоимости присоединения к&nbsp;Оферте некорректных и/или не&nbsp;действительных
                        банковских реквизитов, повлекших за&nbsp;собой возврат на&nbsp;расчетный счет Компании ранее
                        направленной Клиенту суммы денежных средств, Компания вправе при повторной операции возврата
                        стоимости присоединения к&nbsp;Оферте, в&nbsp;рамках исполнения одного и&nbsp;того&nbsp;же
                        заявления Клиента, удержать из&nbsp;суммы возврата штраф в&nbsp;размере 3,2% от&nbsp;стоимости
                        присоединения к&nbsp;Оферте, указанной в&nbsp;Сертификате. Компания вправе налагать данный
                        штраф, в&nbsp;рамках исполнения одного и&nbsp;того&nbsp;же заявления Клиента, эквивалентное
                        количество раз предоставление Клиентом некорректных банковских реквизитов.
                    </p>
                </Form.Item>
            }
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} disabled={!agree || loading || disabled} loading={loading}>Отправить
                    запрос</Button>
            </Form.Item>
        </Form>
    )
}

export default RefundFreeForm