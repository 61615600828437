import {Button, Form, Input} from 'antd'

import { ISignForm} from '../../../shared/forms/forms';
import { segnRegex} from '../../../shared/helpers/regex';

interface SignFormProps {
    onComplete: (values: ISignForm) => void
    loading: boolean
    disabled: boolean
}

const SignForm = ({onComplete, loading, disabled}: SignFormProps) => {

    const [form] = Form.useForm()

    const onFinish = (values: ISignForm) => {
        onComplete(values)
    }

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onChange'} onFinish={onFinish}
              disabled={loading || disabled}>
            <Form.Item name={'code'} label={'Код подписи из письма'}
                       validateFirst={true}
                       rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: segnRegex, message: 'Не соответствует формату кода подписи'},
            ]}>
                <Input placeholder={'00000000'}/>
            </Form.Item>
                <p>
                    Не закрывая это окно, проверьте входящую почту, в том числе папку &laquo;Спам&raquo;
                </p>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>Подписать заявление</Button>
            </Form.Item>
        </Form>
    )
}

export default SignForm