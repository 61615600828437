import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {MenuOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Button, ConfigProvider, Dropdown, Space} from 'antd';
import _ from 'lodash'

import {ABOUT, ACTIVATE, CONSULTATION, INDEX, LOSS, PARTNER, SERVICES} from '../../config/routes';
import logo from '../../images/fts-group-logo.svg';

import cls from './Header.module.scss';


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export const menuItems = [
    {
        link: ABOUT,
        label: 'О компании',
    },
    {
        link: PARTNER,
        label: 'Сотрудничество',
    },
    {
        link: SERVICES,
        label: 'Сервисы',
    },
    // {
    //     link: FAQ,
    //     label: 'FAQ ',
    // },
]

const Header = () => {

    const navigate = useNavigate();

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // смэпили для Menu
    const items: MenuProps['items'] = _.map(menuItems, item => ({
        key: item.link,
        label: (
            <Link to={item.link}>{item.label}</Link>
        ),
    }))

    const buttons =
        <>
            <Button type={'primary'} onClick={() => navigate(CONSULTATION)}>Онлайн консультация</Button>
            <ConfigProvider theme={{
                token: {
                    colorPrimary: '#00A6CE',
                },
            }}>
                <Button type={'primary'} onClick={() => navigate(ACTIVATE)}>Активировать сертификат</Button>
            </ConfigProvider>
            <Button onClick={() => navigate(LOSS)}>Заявить убыток</Button>
        </>

    return (
        <div className={cls.header}>
            <div className={cls.top}>
                <div>
                    <Link to={INDEX}>
                        <img src={logo} alt={'logo'} className={cls.logo}/>
                    </Link>
                </div>
                <div>
                    <Space>
                        {width > 1160
                            ?
                            _.map(menuItems, item => (
                                <Button type={'text'} key={item.link}
                                        onClick={() => navigate(item.link)}>{item.label}</Button>
                            ))
                            :
                            <Dropdown menu={{items}}>
                                <Button type={'text'} icon={<MenuOutlined/>}/>
                            </Dropdown>
                        }

                        {/*{buttons}*/}

                        {width > 760 && buttons}
                        
                    </Space>
                </div>
            </div>

            {width <= 760 &&
                <div className={width > 560 ? cls.buttonsRow : cls.buttonsLine}>
                    {buttons}
                </div>
            }
        </div>
    );
}

export default Header;