import {gql} from '@apollo/client';

export const QUERY_CHECK_REFUND_STATUS = gql`
    query checkRefundStatus ($Certificate_Number: String!) {
        checkRefundStatus(Certificate_Number: $Certificate_Number) {
            status
            signStatus
        }
    }
`

export const QUERY_CHECK_CERT_NUMBER = gql`
    query checkCertNumber ($Certificate_Number: String!) {
        checkCertNumber(Certificate_Number: $Certificate_Number) {
            template
            application
            details
        }
    }
`

export const MUTATION_SIGN_REFUND_BY_CODE = gql`
    mutation signRefundByCode ($id: String!, $code: String!) {
        signRefundByCode(code: $code, id: $id) {
            signStatus
            template
            requestPdfFileUrl
        }
    }
`

export const CREATE_REFUND_REQUEST = gql`
    mutation createRefundRequest ($data: RefundRequestInput!) {
        createRefundRequest(data: $data) {
            data {
                id
                attributes {
                    signStatus
                    template
                    requestPdfFileUrl
                }
            }
        }
    }
`