import {useEffect, useState} from 'react';
import {useInstance} from 'react-ioc';
import ym from 'react-yandex-metrika';
import {CloseOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {observer} from 'mobx-react-lite';

import {Store} from '../../model/store/Store';

import {OnlyCash} from './OnlyCash/OnlyCash';

import cls from './Banner.module.scss'

const secondsToOpen = 5
const secondsToClose = 5

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export const Banner = observer(() => {

    const {banner} = useInstance(Store)

    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const onOpen = () => {
        if (banner.needOpen) {
            setOpen(true)
            setLoading(true)
            banner.toOpen()
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            document.documentElement.style.setProperty('--scroll-position', `${scrollY}px`);
            document.body.classList.add('no-scroll'); /* Прокрутка ставится на паузу */
            ym('reachGoal', 'bannerOnlyCash');
            setTimeout(() => setLoading(false), secondsToClose * 1000)
        }
    }

    const onClose = () => {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-position');
        document.documentElement.style.removeProperty('--scroll-position');
        document.body.classList.remove('no-scroll'); /* Прокрутка возобновляется */
        window.scrollTo(0, Number(scrollY.slice(0, -2)));
        setOpen(false)
    }

    useEffect(() => {
        const interval = setTimeout(() => onOpen(), secondsToOpen * 1000);
        return () => {
            clearTimeout(interval);
        };
    }, []);

    const [width, setWidth] = useState(getWindowDimensions().width);
    const [height, setHeight] = useState(getWindowDimensions().height);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
            setHeight(getWindowDimensions().height);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // console.log('width',width)
    // console.log('height',height)

    const desktopStyle = {
        width: 'calc(100% - 80px)',
        maxWidth: 800,
        height: 'calc(100% - 80px)',
        maxHeight: 460,
        borderRadius: 16,
    }
    const mobileStyleV = {
        width: '100%',
        minWidth: 320,
        height: '100%',
        minHeight: 690,
        borderRadius: 0,
    }
    const mobileStyleH = {
        width: '100%',
        minWidth: 600,
        height: '100%',
        minHeight: 320,
        borderRadius: 0,
    }
    const isDesktop = width >= 768 && height >= 430

    if (open) {
        return (
            <div className={cls.root}>
                <div className={cls.modal} style={isDesktop ? desktopStyle : width > height ? mobileStyleH : mobileStyleV}>
                    <div className={cls.control}>
                        <span className={cls.label}>Реклама</span>
                        <Button icon={<CloseOutlined/>} onClick={() => onClose()} type={'text'} loading={loading}/>
                    </div>
                    <div className={cls.content}>
                        <OnlyCash horizontal={width > height} onClick={() => ym('reachGoal', 'clickOnlyCash')}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
})