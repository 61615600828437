import React, {FC} from 'react';
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import {YMInitializer} from 'react-yandex-metrika';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {ConfigProvider} from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import {observer} from 'mobx-react-lite';

import 'dayjs/locale/ru';

import { Metrika } from '../components/common/Metrika';
import ScrollToTop from '../components/common/ScrollToTop';
import Layout from '../components/layout/Layout';
import {
    ABOUT,
    ACT,
    ACT_SUCCESS,
    ACTIVATE,
    ACTIVATE_SUCCESS,
    CONSULTATION,
    CONSULTATION_SUCCESS,
    DOCUMENTS,
    ERROR_CERT_NUMBER,
    FAQ,
    INDEX,
    LOGIN,
    LOSS,
    LOSS_SUCCESS,
    NOT_FOUND,
    PARTNER,
    PARTNER_SUCCESS,
    REFUND,
    REFUND_FREE,
    REFUND_SUCCESS,
    ROOT,
    SERVICES,
    SMS,
    SMS_SUCCESS,
    SUCCESS_DEFAULT_REQUEST,
    SUCCESS_DEFAULT_REQUEST_INS,
    SUCCESS_NON_RETURN,
    SUCCESS_ON_PERSONAL,
    SUCCESS_PURCHASE_PLACE,
    SUCCESS_SEVEN_DAYS,
    SUCCESS_SMS_REQUEST,
    SUCCESS_WALK_TO_MAIL,
    SUCCESS_WALK_TO_MFO,
    SUCCESS_WALK_TO_OFFICE,
} from '../config/routes';
import ApolloClientDI from '../model/graphql/ApolloClientDI';
import StoreDI from '../model/store/StoreDI';
import About from '../pages/About/About';
import Act from '../pages/Act/Act';
import {ActSuccess} from '../pages/Act/ActSuccess';
import Activate from '../pages/Activate/Activate';
import Consultation from '../pages/Consultation/Consultation';
import {ConsultationSuccess} from '../pages/Consultation/ConsultationSuccess';
import Document from '../pages/Document/Document';
import Faq from '../pages/Faq/Faq';
import Index from '../pages/index/Index';
import Login from '../pages/Login/Login';
import Loss from '../pages/Loss/Loss';
import NotFound from '../pages/NotFound';
import Partner from '../pages/Partner/Partner';
import {ErrorCertNumber} from '../pages/Refund/Free/Error/ErrorCertNumber';
import RefundFree from '../pages/Refund/Free/RefundFree';
import { SuccessDefaultRequest} from '../pages/Refund/Free/Success/SuccessDefaultRequest';
import {SuccessDefaultRequestIns} from '../pages/Refund/Free/Success/SuccessDefaultRequestIns';
import {SuccessNonReturn} from '../pages/Refund/Free/Success/SuccessNonReturn';
import {SuccessOnPersonal} from '../pages/Refund/Free/Success/SuccessOnPersonal';
import {SuccessPurchasePlace} from '../pages/Refund/Free/Success/SuccessPurchasePlace';
import {SuccessSevenDays} from '../pages/Refund/Free/Success/SuccessSevenDays';
import {SuccessSMSRequest} from '../pages/Refund/Free/Success/SuccessSMSRequest';
import {SuccessWalkToMail} from '../pages/Refund/Free/Success/SuccessWalkToMail';
import {SuccessWalkToMFO} from '../pages/Refund/Free/Success/SuccessWalkToMFO';
import {SuccessWalkToOffice} from '../pages/Refund/Free/Success/SuccessWalkToOffice';
import {Services} from '../pages/Services/Services';
import Sms from '../pages/Sms/Sms';
import {SmsSuccess} from '../pages/Sms/SmsSuccess';
import {ActivateSuccess} from '../pages/Success/ActivateSuccess';
import {RefundSuccess} from '../pages/Success/RefundSuccess';
import {Success} from '../pages/Success/Success';

import './App.scss';

const App: FC = () => {

    const apolloClient = useInstance(ApolloClient);

    return (
        <ConfigProvider locale={ru_RU} theme={{
            token: {
                colorPrimary: '#5CD6CE',
            },
        }}>
            <ApolloProvider client={apolloClient}>
                <Router>
                    <ScrollToTop/>
                    <YMInitializer accounts={[98242596]}/>
                    <Metrika/>

                    <Routes>

                        <Route path={ROOT} element={<Layout/>}>
                            <Route path={INDEX} element={<Index/>}/>
                            <Route path={ABOUT} element={<About/>}/>
                            <Route path={PARTNER} element={<Partner/>}/>
                            <Route path={PARTNER_SUCCESS} element={<Success/>}/>
                            <Route path={LOSS} element={<Loss/>}/>
                            <Route path={LOSS_SUCCESS} element={<Success/>}/>
                            <Route path={ACTIVATE} element={<Activate/>}/>
                            <Route path={ACTIVATE_SUCCESS} element={<ActivateSuccess/>}/>

                            <Route path={ACT} element={<Act/>}/>
                            <Route path={ACT_SUCCESS} element={<ActSuccess/>}/>

                            <Route path={SMS} element={<Sms/>}/>
                            <Route path={SMS_SUCCESS} element={<SmsSuccess/>}/>

                            <Route path={FAQ} element={<Faq/>}/>
                            <Route path={REFUND} element={<RefundFree/>}/>
                            <Route path={REFUND_SUCCESS} element={<RefundSuccess/>}/>
                            <Route path={REFUND_FREE} element={<RefundFree/>}/>

                            <Route path={SUCCESS_SEVEN_DAYS} element={<SuccessSevenDays/>}/>
                            <Route path={SUCCESS_WALK_TO_MFO} element={<SuccessWalkToMFO/>}/>
                            <Route path={SUCCESS_PURCHASE_PLACE} element={<SuccessPurchasePlace/>}/>
                            <Route path={SUCCESS_DEFAULT_REQUEST} element={<SuccessDefaultRequest/>}/>
                            <Route path={SUCCESS_SMS_REQUEST} element={<SuccessSMSRequest/>}/>
                            <Route path={SUCCESS_DEFAULT_REQUEST_INS} element={<SuccessDefaultRequestIns/>}/>
                            <Route path={SUCCESS_ON_PERSONAL} element={<SuccessOnPersonal/>}/>
                            <Route path={ERROR_CERT_NUMBER} element={<ErrorCertNumber/>}/>

                            <Route path={SUCCESS_WALK_TO_OFFICE} element={<SuccessWalkToOffice/>}/>
                            <Route path={SUCCESS_WALK_TO_MAIL} element={<SuccessWalkToMail/>}/>
                            <Route path={SUCCESS_NON_RETURN} element={<SuccessNonReturn/>}/>

                            <Route path={SERVICES} element={<Services/>}/>
                            <Route path={CONSULTATION} element={<Consultation/>}/>
                            <Route path={CONSULTATION_SUCCESS} element={<ConsultationSuccess/>}/>
                            <Route path={DOCUMENTS} element={<Document/>}/>
                            <Route path={LOGIN} element={<Login/>}/>
                            <Route path="*" element={<Navigate to={INDEX}/>}/>
                            <Route path={NOT_FOUND} element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </Router>
            </ApolloProvider>
        </ConfigProvider>
);
};

export default provider(
StoreDI(),
ApolloClientDI(),
)(observer(App));
