// INDEX
export const ROOT = '';
export const INDEX = '/';
export const ABOUT = '/about';
export const PARTNER = '/partner';
export const PARTNER_SUCCESS = '/partner/success';
export const LOSS = '/loss';
export const LOSS_SUCCESS = '/loss/success';
export const SERVICES = '/services';
export const FAQ = '/faq';
export const CONSULTATION = '/consultation';
export const CONSULTATION_SUCCESS = '/consultation/success';
export const REFUND = '/refund';
export const REFUND_FREE= '/refund/free';

export const SUCCESS_SEVEN_DAYS= '/refund/free/seven';
export const SUCCESS_WALK_TO_MFO= '/refund/free/mfo';
export const SUCCESS_PURCHASE_PLACE= '/refund/free/purchase';
export const SUCCESS_DEFAULT_REQUEST= '/refund/free/default';
export const SUCCESS_SMS_REQUEST= '/refund/free/sms';
export const SUCCESS_DEFAULT_REQUEST_INS= '/refund/free/default/ins';
export const SUCCESS_ON_PERSONAL = '/refund/free/personal';
export const SUCCESS_WALK_TO_OFFICE= '/refund/free/office';
export const SUCCESS_WALK_TO_MAIL= '/refund/free/mail';
export const SUCCESS_NON_RETURN= '/refund/free/noreturn';
export const ERROR_CERT_NUMBER = '/refund/free/cert-error';

export const REFUND_SUCCESS = '/refund/success';
export const LOGIN = '/login';
export const DOCUMENTS = '/doc';
export const ACTIVATE = '/activate';
export const ACTIVATE_SUCCESS = '/activate/success';

export const ACT = '/act/:code';
export const ACT_SUCCESS = '/act/success';

export const SMS = '/sms/:code';
export const SMS_SUCCESS = '/sms/success';



export const POLICY = '/docs/Политика_конфеденциальности.pdf';
export const OFFER_REFUND = '/docs/Оферта_Платные_расторжения.pdf';
export const OFFER_MED = '/docs/Оферта_Правила_абоненткого_обслуживания_Онлайн_консультация_с_врачом.pdf';
export const OFFER_MED_ONLINE = '/docs/offer-med-online.pdf';
export const OFFER_LAW = '/docs/Оферта_Правила_абоненткого_обслуживания_Телеюрист.pdf';
export const OFFER_FIN = '/docs/Оферта_Финансовая_защита_ООО_ИТ_Консул_общая.pdf';
export const OFFER_ASTRO = '/docs/Оферта_Правила_абоненткого_обслуживания_Телеастролог.pdf';
export const NOT_FOUND = '/404';

